
import cms from '~/mixins/common/cms'
import playGames from '~/mixins/common/play-games'
export default {
    mixins: [cms, playGames],
    props: {
        toggle: {
            type: Function,
            default: () => {},
        },
        index: {
            type: Number,
            default: 1,
        },
        cardWidth: {
            type: Number,
            default: 1,
        },
        promotion: {
            type: Object,
            default: () => {},
        },
        code: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            imageWidth: 500,
            imageHeight: 300,
        }
    },
    watch: {
        cardWidth(val) {
            this.imageHeight = (300 * val) / 500
        },
    },
    mounted() {
        this.imageHeight = (300 * this.cardWidth) / 500
    },
    methods: {},
}
